const TypesUtil = {
  // IMPORTANT: if this file changes, we need to update the Types Object in extended-base.js.
  //  extended-base cant rely on this file, so we have some duplication

  _HTML_TYPES: [
    'html',
    'xml',
    'haml',
    'markdown',
    'slim',
    'pug',
    'application/x-slim'
  ],
  _CSS_TYPES: [
    'css',
    'less',
    'scss',
    'sass',
    'stylus',
    'postcss',
    'text/css',
    'text/x-sass',
    'text/x-scss',
    'text/x-less',
    'text/x-styl'
  ],
  _JS_TYPES: [
    'js',
    'javascript',
    'coffeescript',
    'livescript',
    'typescript',
    'babel',
    'text/javascript',
    'text/x-coffeescript',
    'text/x-livescript',
    'text/typescript'
  ],
  _CUSTOM_EDITOR_TYPES: {
    vue: 'js',
    flutter: 'js'
  },

  /*
   * Translate an editor mode (xml, javascript, css) to a valid
   * Data object data type (html, css, js)
   */
  cmModeToType: function (mode: string) {
    const inputMode = this._getSafeInputMode(mode);
    return this._getType(inputMode);
  },

  // NOTE: Be very careful in here. Several bugs came from here.
  // NOTE: mode for HTML is an object. CSS and JS are strings
  // It is not that way for CSS or JS.
  _getSafeInputMode: function (mode: string | { name: string }) {
    let inputMode = '';
    if (typeof mode === 'string') {
      inputMode = mode;
    } else if (mode && typeof mode === 'object' && 'name' in mode) {
      inputMode = mode.name;
    }
    return inputMode.toLowerCase();
  },

  syntaxToType: function (syntax: string) {
    return this._getType(syntax);
  },

  // Match more closely with extended-base.js and drop the underscore.js requirement
  _getType: function (syntax: string) {
    if (this._HTML_TYPES.includes(syntax)) {
      return 'html';
    } else if (this._CSS_TYPES.includes(syntax)) {
      return 'css';
    } else if (this._JS_TYPES.includes(syntax)) {
      return 'js';
    } else if (
      this._CUSTOM_EDITOR_TYPES[
        syntax as keyof typeof this._CUSTOM_EDITOR_TYPES
      ]
    ) {
      return this._CUSTOM_EDITOR_TYPES[
        syntax as keyof typeof this._CUSTOM_EDITOR_TYPES
      ];
    } else {
      return 'unknown';
    }
  }
};

export default TypesUtil;
