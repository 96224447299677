import { buildURL, getHeight, getPrefillData, Params } from './embed_util';

/*****************
  Build And Embed the iFrame & Forms
  *****************/

export const buildAndEmbed = (params: Params, el: HTMLParagraphElement) => {
  let form;
  const fragment = document.createDocumentFragment();
  fragment.append(buildIframe(params));

  // If prefill, the form must be built to submit POST data to the iframe.
  // The form has to be submitted when it's in the DOM. To prevent any thrashing, the form is added to the fragment first.
  if ('prefill' in params) {
    form = buildPrefillForm(params, el);
    fragment.append(form);
  }

  // Fragment is added to the page.
  addIframeToPage(el, fragment);

  // Form, if exists, is submitted.
  if (form) {
    (form as HTMLFormElement).submit();
  }
};

/*****************
      Create Element shortcut for attributes
      *****************/

interface ElementAttributes {
  [key: string]: string;
}

export const createElement = (
  tagName: string,
  attributes: ElementAttributes
): HTMLElement => {
  const el = document.createElement(tagName);
  for (const key in attributes) {
    if (Object.prototype.hasOwnProperty.call(attributes, key)) {
      el.setAttribute(key, attributes[key]);
    }
  }
  return el;
};

export const buildPrefillForm = (params: Params, el: HTMLParagraphElement) => {
  const form = createElement('form', {
    class: 'cp_embed_form',
    style: 'display: none;',
    method: 'post',
    action: buildURL(params),
    target: params.name as string
  });

  // Translate the prefill data to what the API expects
  params['data'] = getPrefillData(el);

  // Append params as <inputs>
  for (const key in params) {
    if (key === 'prefill') continue;

    form.append(
      createElement('input', {
        type: 'hidden',
        name: key,
        value: params[key] as string
      })
    );
  }

  return form;
};

/*****************
  Build Iframe
  *****************/

export const buildIframe = (params: Params) => {
  const url = buildURL(params);
  const title: string =
    typeof params['pen-title'] === 'string'
      ? params['pen-title']
      : 'CodePen Embed';

  const attrs: ElementAttributes = {
    allowfullscreen: 'true',
    allowpaymentrequest: 'true',
    allowTransparency: 'true',
    class: 'cp_embed_iframe ' + (params['class'] || ''),
    frameborder: '0',
    height: getHeight(params).toString(),
    width: '100%',
    name: typeof params.name === 'string' ? params.name : 'CodePen Embed',
    scrolling: 'no',
    src: url,
    style: 'width: 100%; overflow:hidden; display:block;',
    title: title
    // Don't add loading: lazy here, see below...
  };

  /* Don't lazy load prefill embeds. Causes issues with the form submission so the POST data never comes through! */
  if ('prefill' in params === false) {
    attrs.loading = 'lazy';
  }

  if (params['slug-hash']) {
    attrs.id = 'cp_embed_' + params['slug-hash'].replace('/', '_');
  }

  return createElement('iframe', attrs);
};

/*
 * Replaces the initial element with the iframe. If the element is not attached in the DOM, it adds the iframe to the element.
 */
export const addIframeToPage = (el: Element, iframe: Node) => {
  if (el.parentNode) {
    const iframeSpan = document.createElement('div');
    iframeSpan.className = 'cp_embed_wrapper';
    iframeSpan.append(iframe);
    el.parentNode.replaceChild(iframeSpan, el);
    return iframeSpan;
  }

  el.append(iframe);
  return el;
};
