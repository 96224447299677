import { buildAndEmbed } from './embed_dom';
import { callCallback, domReady, getParamsFromAttributes } from './embed_util';

let numOfEmbeds = 1;

function CPEmbed(selector: string | undefined) {
  // If we get a string, use that class instead of .codepen
  selector = typeof selector === 'string' ? selector : '.codepen';

  const codePenElements = document.querySelectorAll(selector);

  for (let i = 0, len = codePenElements.length; i < len; i++) {
    const el = codePenElements[i];
    const params = getParamsFromAttributes(el);

    // This will filter out other elements with the class "codepen" ... that are not meant to be embeds.
    if (!params) {
      continue;
    }

    params.name = 'cp_embed_' + numOfEmbeds++;
    buildAndEmbed(params, el as HTMLParagraphElement);
  }

  callCallback();
}

// @ts-ignore
window.__cp_domReady = domReady;
// @ts-ignore
window.__CPEmbed = CPEmbed;

domReady(CPEmbed);
