import { NEW_EDITOR_PATH } from '@codepen/constants';

import TypesUtil from './types_util';

export enum ParamKeys {
  slugHash = 'slug-hash',
  token = 'token',
  height = 'height',
  preview = 'preview',
  class = 'class',
  zoom = 'zoom',
  prefill = 'prefill',
  editable = 'editable',
  host = 'host',
  file = 'file',
  newEditor = 'new-editor',
  defaultTab = 'default-tab',
  animations = 'animations',
  border = 'border',
  user = 'user',
  borderColor = 'border-color',
  tabBarColors = 'tab-bar-colors',
  tabLinkColor = 'tab-link-color',
  themeId = 'theme-id',
  activeTabColor = 'active-tab-color',
  activeLinkColor = 'active-link-color',
  linkLogoColor = 'link-logo-color',
  customCssUrl = 'custom-css-url',
  penTitle = 'pen-title',
  safe = 'safe',
  type = 'type',
  href = 'href'
}

export interface Params {
  // New Public Docs: https://blog.codepen.io/docs/embeds/themes/

  [key: string]: unknown;

  // Required (unless Prefill)
  [ParamKeys.slugHash]?: string;
  [ParamKeys.token]?: string; // If Private

  // Standard Options
  [ParamKeys.height]?: number;
  [ParamKeys.preview]?: string | boolean; // true = "Click to Run" false = Don't show at all.
  [ParamKeys.class]?: string;
  [ParamKeys.zoom]?: number;
  [ParamKeys.themeId]?: number;

  // Types of Embeds
  [ParamKeys.prefill]?: string; // prefill='{"title": "My Pen"}' etc.
  [ParamKeys.editable]?: boolean;

  // Internal usage only
  [ParamKeys.host]?: string; // e.g. data-host="codepen.test" for local testing.

  // New Editor Specific
  [ParamKeys.file]?: string;

  // Temporary, until New Editor is the Only Editor
  [ParamKeys.newEditor]?: boolean;

  // Deprecated (but still supported)
  [ParamKeys.defaultTab]?: string; // will become `file` and effect preview visibility

  // Deprecating once New Editor is the Only Editor
  [ParamKeys.animations]?: string; // 'run' : 'stop-after-5' // deprecating
  [ParamKeys.border]?: string;
  [ParamKeys.user]?: string;
  [ParamKeys.borderColor]?: string;
  [ParamKeys.tabBarColors]?: string;
  [ParamKeys.tabLinkColor]?: string;
  [ParamKeys.activeTabColor]?: string;
  [ParamKeys.activeLinkColor]?: string;
  [ParamKeys.linkLogoColor]?: string;
  [ParamKeys.customCssUrl]?: string;
  [ParamKeys.penTitle]?: string;

  // VERY Deprecated
  [ParamKeys.safe]?: boolean | string;
  [ParamKeys.type]?: string;
  [ParamKeys.href]?: string;
}

// Adapted from http://www.dustindiaz.com/smallest-domready-ever
export const domReady = (f: (selector?: string | undefined) => void) => {
  if (document.readyState === 'loading') {
    setTimeout(() => {
      domReady(f);
    }, 8);
  } else {
    f();
  }
};

declare const __CodePenIFrameAddedToPage: (() => void) | undefined;
export const callCallback = () => {
  if (typeof __CodePenIFrameAddedToPage === 'function') {
    __CodePenIFrameAddedToPage();
  }
};

const prefillWhitelist = new Set([
  'title',
  'description',
  'tags',
  'html_classes',
  'head',
  'stylesheets',
  'scripts'
]);

export const getPrefillData = (el: HTMLParagraphElement) => {
  if (Object.prototype.hasOwnProperty.call(el.dataset, 'prefill')) {
    const data: { [key: string]: unknown } = {};

    // Parse the data-prefill attribute as JSON for additional options.
    const prefill = JSON.parse(decodeURI(el.dataset.prefill ?? '{}'));

    // White-list prefill data to send through.
    for (const key in prefill) {
      if (prefillWhitelist.has(key)) {
        data[key] = prefill[key];
      }
    }

    // Seek out any data-lang children to fill the html/css/js fields.
    const codeBlocks = el.querySelectorAll('[data-lang]');
    for (const codeBlock of codeBlocks) {
      const lang = (codeBlock as HTMLElement).dataset.lang;

      const autoprefixer = (codeBlock as HTMLElement).dataset
        .optionsAutoprefixer;
      if (autoprefixer) {
        data['css_prefix'] = 'autoprefixer';
      }

      // Convert letious preprocesors to the proper type
      const type = TypesUtil.syntaxToType(lang || '');
      // eslint-disable-next-line unicorn/prefer-dom-node-text-content
      data[type] = (codeBlock as HTMLElement).innerText;

      // Send through a preprocessor if used
      if (lang !== type) {
        data[type + '_pre_processor'] = lang;
      }

      // Send through a version if used (primarily for Vue right now)
      const version = (codeBlock as HTMLElement).dataset.langVersion;
      if (version) {
        data[type + '_version'] = version;
      }
    }

    return JSON.stringify(data);
  }
};

export const getParamsFromAttributes = (el: Element) => {
  let params: Params = {};
  const attributes = el.attributes;

  for (let i = 0, l = attributes.length; i < l; i++) {
    const name = attributes[i].name;
    const param = name.replace('data-', '');

    if (name.indexOf('data-') === 0 && isValidParam(param)) {
      params[param] = attributes[i].value;
    }
  }

  params = convertOldDataAttributesToNewDataAttributes(params);

  // If the required attributes are not present, return nothing
  if (!paramsHasRequiredAttributes(params)) {
    return null;
  }

  return params;
};

// Ensure we have the required attributes to create the iframe
export const paramsHasRequiredAttributes = (params: Params) => {
  // Using "in" for prefill because it still counts if it's an null/empty value.
  return 'prefill' in params || params['slug-hash'];
};

/*
 * convert the old data attributes to new better names.
 * - href -> slug-hash
 * - type -> default-tab
 * - safe -> animations
 */
export const convertOldDataAttributesToNewDataAttributes = (params: Params) => {
  if (params.href) {
    params['slug-hash'] = params.href;
  }

  if (params.type) {
    params['default-tab'] = params.type;
  }

  if (params.safe) {
    params.animations = params.safe === 'true' ? 'run' : 'stop-after-5';
  }

  return params;
};

/*****************
  Build URL
  ******************/

export const buildURL = (params: Params) => {
  const host = getHost(params);

  const embedType =
    params.preview && (params.preview === 'true' || params.preview === true)
      ? 'embed/preview'
      : 'embed';

  // Using 'in' to support null data-prefill values
  if ('prefill' in params) {
    return [host, embedType, 'prefill'].join('/');
  }

  const getParams = getGetParams(params);
  const username = params.user || 'anon';
  let hash = params['slug-hash'];
  if (params['token'] !== undefined) {
    hash += '/' + params['token'];
  }

  const url = params['new-editor']
    ? [host, NEW_EDITOR_PATH, username, embedType, hash + '?' + getParams].join(
        '/'
      )
    : [host, username, embedType, hash + '?' + getParams].join('/');

  return url.replace(/\/\//g, '//');
};

export const getHost = (params: Params) => {
  return params.host ? getSafeHost(params.host) : 'https://codepen.io';
};

export const getSafeHost = (host: string): string => {
  return host.match(/^\/\//) || !host.match(/https?:/)
    ? document.location.protocol + '//' + host
    : host;
};

export const getGetParams = (params: Params) => {
  let dataValues = '';

  for (const key in params) {
    if (key === 'prefill') {
      continue;
    }
    if (dataValues !== '') {
      dataValues += '&';
    }

    const value = params[key];
    let encoded;

    if (
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean'
    ) {
      encoded = encodeURIComponent(value);
    } else {
      throw new TypeError('Invalid parameter type');
    }
    dataValues += key + '=' + encoded;
  }

  return dataValues;
};

/*
 * Get height, defaults to magic 300
 */
export const getHeight = (params: Params) => {
  return params.height || 300;
};

export const isValidParam = (param: string) => {
  return Object.values(ParamKeys).includes(param as ParamKeys);
};
